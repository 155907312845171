import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,

  Card,
  CardBody,

  // Dropdown,
  // DropdownToggle,
  // DropdownItem,
  // DropdownMenu
} from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
// Custom Scrollbar


// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";
import servicesIcon2 from "../../assets/images/services-icon/02.png";
import servicesIcon3 from "../../assets/images/services-icon/03.png";
import servicesIcon4 from "../../assets/images/services-icon/04.png";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";


// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import RadialChart from "../AllCharts/apex/apexdonut";
import Apexdonut from "../AllCharts/apex/apexdonut1";
import SparkLine from "../AllCharts/sparkline/sparkline";
import SparkLine1 from "../AllCharts/sparkline/sparkline1";
import Salesdonut from "../AllCharts/apex/salesdonut";

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";

const Dashboard = () => {
  const [apartmentcount,setApartmentcount] = useState(0)
  const [residenceCount, setResidenceCount] = useState(0);
  const [bookingCount, setBookingCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  const [tarnsactionData,setTrancsactionData] =useState()
  // const [menu, setMenu] = useState(false);
  // const toggle = () => {
  //   setMenu(!menu);
  // };
  const userImages = [user2, user3, user4, user5, user6]; 
  const obj = JSON.parse(localStorage.getItem("permission"));
  const userRole = obj?.role_name;

  // Check if the user is Admin
  const isAdmin = userRole === "Admin";
  const fetchCounts = async () => {
    try {
      const token = authHeader(); // Fetch the authorization token
      const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
      const userId = user.user?.id;

  

  
      const config = {
        headers: {
          Authorization: token,
        },
      };
  
      // Initialize params
      const params = new URLSearchParams();
      if (!isAdmin) {
        params.append("owner_id", userId);
      }
  
      // Define URLs based on user role
      const apartmentUrl = isAdmin ? `/apartmentswithsearch?${params.toString()}` : `apartmentswithsearch`;
      const residenceUrl = isAdmin ? `/allresidenceswithfilter?${params.toString()}` : `allresidenceswithfilter`;
      const bookingsUrl = isAdmin ? `/bookingswithfilters?${params.toString()}` : `bookingswithfilters`;
       const userSUrl  = isAdmin?"/users":""
      // Make all requests concurrently
      const [apartmentRes, residenceRes, bookingsRes,userRes] = await Promise.all([
        get(apartmentUrl, config),
        get(residenceUrl, config),
        get(bookingsUrl, config),
        get(userSUrl, config),

      ]);
      setTrancsactionData(bookingsRes?.data)
      console.log("Bookings Response:", bookingsRes);
   console.log("responseeee",userRes)
      // Update state based on responses
      setUserCount(userRes?.pagination?.totalItems )
      setApartmentcount(apartmentRes?.pagination?.totalItems || 0);
      setResidenceCount(residenceRes?.pagination?.totalItems || 0);
      setBookingCount(bookingsRes?.total || 0);
  
    } catch (error) {
      console.log("Error:", error);
    }
  };
  
  useEffect(() => {
    fetchCounts();
  }, []);
  console.log("countssss",apartmentcount,residenceCount,bookingCount)
  document.title = "Dashboard | SHS  Admin";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to  SHS  Dashboard</li>
                </ol>
              </Col>

       
            </Row>
          </div>
          <Row>
      <Col xl={isAdmin?"3":"4"} md={6}>
        <Card className="mini-stat bg-primary text-white">
          <CardBody>
            <div className="mb-4">
              <div className="float-start mini-stat-img me-4">
                <img src={servicesIcon1} alt="" />
              </div>
              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">Apartments</h5>
              <h4 className="fw-medium font-size-24">
                {apartmentcount}
                <i className="mdi mdi-arrow-up text-success ms-2"></i>
              </h4>
              <div className="mini-stat-label bg-success">
                <p className="mb-0">+ 12%</p>
              </div>
            </div>
            <div className="pt-2">
              <div className="float-end">
                <Link to="#" className="text-white-50">
                  <i className="mdi mdi-arrow-right h5"></i>
                </Link>
              </div>
              <p className="text-white-50 mb-0 mt-1">Since last month</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={isAdmin?"3":"4"} md={6}>
        <Card className="mini-stat bg-primary text-white">
          <CardBody>
            <div className="mb-4">
              <div className="float-start mini-stat-img me-4">
                <img src={servicesIcon2} alt="" />
              </div>
              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">Residences</h5>
              <h4 className="fw-medium font-size-24">
                {residenceCount}
                <i className="mdi mdi-arrow-down text-danger ms-2"></i>
              </h4>
              <div className="mini-stat-label bg-danger">
                <p className="mb-0">- 28%</p>
              </div>
            </div>
            <div className="pt-2">
              <div className="float-end">
                <Link to="#" className="text-white-50">
                  <i className="mdi mdi-arrow-right h5"></i>
                </Link>
              </div>
              <p className="text-white-50 mb-0 mt-1">Since last month</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={isAdmin?"3":"4"} md={6}>
        <Card className="mini-stat bg-primary text-white">
          <CardBody>
            <div className="mb-4">
              <div className="float-start mini-stat-img me-4">
                <img src={servicesIcon3} alt="" />
              </div>
              <h5 className="font-size-16 text-uppercase mt-0 text-white-50">Bookings</h5>
              <h4 className="fw-medium font-size-24">
                {bookingCount}
                <i className="mdi mdi-arrow-up text-success ms-2"></i>
              </h4>
              <div className="mini-stat-label bg-info">
                <p className="mb-0">00%</p>
              </div>
            </div>
            <div className="pt-2">
              <div className="float-end">
                <Link to="#" className="text-white-50">
                  <i className="mdi mdi-arrow-right h5"></i>
                </Link>
              </div>
              <p className="text-white-50 mb-0 mt-1">Since last month</p>
            </div>
          </CardBody>
        </Card>
      </Col>
      {isAdmin && (
        <Col xl={3} md={6}>
          <Card className="mini-stat bg-primary text-white">
            <CardBody>
              <div className="mb-4">
                <div className="float-start mini-stat-img me-4">
                  <img src={servicesIcon4} alt="" />
                </div>
                <h5 className="font-size-16 text-uppercase mt-0 text-white-50">USERS</h5>
                <h4 className="fw-medium font-size-24">
                {userCount} <i className="mdi mdi-arrow-up text-success ms-2"></i>
                </h4>
                <div className="mini-stat-label bg-warning">
                  <p className="mb-0">+ 84%</p>
                </div>
              </div>
              <div className="pt-2">
                <div className="float-end">
                  <Link to="#" className="text-white-50">
                    <i className="mdi mdi-arrow-right h5"></i>
                  </Link>
                </div>
                <p className="text-white-50 mb-0 mt-1">Since last month</p>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>

          <Row>
            <Col xl={9}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Monthly Earning</h4>
                  <Row>
                    <Col lg={7}>
                      <div>
                        <LineAreaChart />
                      </div>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">This month</p>
                            <h3>$34,252</h3>
                            <p className="text-muted mb-4">
                              It will be as simple as in fact it will be
                              occidental.
                            </p>
                            <RadialChart />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="text-center">
                            <p className="text-muted mb-4">Last month</p>
                            <h3>$36,253</h3>
                            <p className="text-muted mb-4">
                              It will be as simple as in fact it will be
                              occidental.
                            </p>
                            <Apexdonut />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={3}>
              <Card>
                <CardBody>
                  <div>
                    <h4 className="card-title mb-4">Sales Analytics</h4>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Online</p>
                          <h5 className="mb-4">1,542</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wid-peity mb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Offline</p>
                          <h5 className="mb-4">6,451</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine1 />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <p className="text-muted">Marketing</p>
                          <h5>84,574</h5>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4">
                          <SparkLine />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={3}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Sales Report</h4>
                  <div className="cleafix">
                    <p className="float-start">
                      <i className="mdi mdi-calendar me-1 text-primary"></i> Jan
                      01 - Jan 31
                    </p>
                    <h5 className="font-18 text-end">$4230</h5>
                  </div>
                  <div id="ct-donut" className="ct-chart wid pt-4">
                    <Salesdonut />
                  </div>
                  <div className="mt-4">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <span className="badge bg-primary">Desk</span>
                          </td>
                          <td>Desktop</td>
                          <td className="text-end">54.5%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-success">Mob</span>
                          </td>
                          <td>Mobile</td>
                          <td className="text-end">28.0%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-warning">Tab</span>
                          </td>
                          <td>Tablets</td>
                          <td className="text-end">17.5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Activity</h4>
                  <ol className="activity-feed">
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 22</span>
                        <span className="activity-text">
                          Responded to need “Volunteer Activities”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 20</span>
                        <span className="activity-text">
                          At vero eos et accusamus et iusto odio dignissimos
                          ducimus qui deleniti atque...
                          <Link to="#" className="text-success">
                            Read more
                          </Link>
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 19</span>
                        <span className="activity-text">
                          Joined the group “Boardsmanship Forum”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 17</span>
                        <span className="activity-text">
                          Responded to need “In-Kind Opportunity”
                        </span>
                      </div>
                    </li>
                    <li className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">Jan 16</span>
                        <span className="activity-text">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          rem.
                        </span>
                      </div>
                    </li>
                  </ol>
                  <div className="text-center">
                    <Link to="#" className="btn btn-primary">
                      Load More
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={5}>
              <Row>
                <Col md={6}>
                  <Card className="text-center">
                    <CardBody>
                      <div className="py-4">
                        <i className="ion ion-ios-checkmark-circle-outline display-4 text-success"></i>

                        <h5 className="text-primary mt-4">Order Successful</h5>
                        <p className="text-muted">
                          Thanks you so much for your order.
                        </p>
                        <div className="mt-4">
                          <Link to="#" className="btn btn-primary btn-sm">
                            Chack Status
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card className="bg-primary">
                    <CardBody>
                      <div className="text-center text-white py-4">
                        <h5 className="mt-0 mb-4 text-white-50 font-size-16">
                          Top Product Sale
                        </h5>
                        <h1>1452</h1>
                        <p className="font-size-14 pt-1">Computer</p>
                        <p className="text-white-50 mb-0">
                          At solmen va esser necessi far uniform myth...{" "}
                          <Link to="#" className="text-white">
                            View more
                          </Link>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Client Reviews</h4>
                      <p className="text-muted mb-3 pb-4">
                      &quot Everyone realizes why a new common language would be
                        desirable one could refuse to pay expensive translators
                        it would be necessary. &quot
                      </p>
                      <div className="float-end mt-2">
                        <Link to="#" className="text-primary">
                          <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                      </div>
                      <h6 className="mb-0">
                        {" "}
                        <img
                          src={user3}
                          alt=""
                          className="avatar-sm rounded-circle me-2"
                        />{" "}
                        James Athey
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Latest Transaction</h4>
                  <div className="table-responsive">
      <table className="table table-hover table-centered table-nowrap mb-0">
        <thead>
          <tr>
            <th scope="col">(#) Id</th>
            <th scope="col">Name</th>
            <th scope="col">Date</th>
            <th scope="col">Amount</th>
            <th scope="col" colSpan="2">Status</th>
          </tr>
        </thead>
   {  tarnsactionData?.length>0?  ( <tbody>
          {tarnsactionData?.map((item, index) => {
       const fullName = item.detail?.billing.first_name + ' ' + item.detail?.billing.last_name;
            const date = new Date(item.created_at);
            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            const amount = item.detail?.items[0].total || '0'; // Assuming amount is in detail object
            const statusBadge = item.status === 'completed' 
              ? <span className="badge bg-success">Delivered</span>
              : item.status === null 
              ? <span className="badge bg-warning">Pending</span>
              : <span className="badge bg-danger">Cancel</span>;

            return (
              <tr key={item.id}>
                <th scope="row">#{item.id}</th>
                <td>
                  <div>
                    <img src={userImages[index % userImages.length]} alt="" className="avatar-xs rounded-circle me-2" />
                  {fullName} {/* Replace with actual name if available */}
                  </div>
                </td>
                <td>{formattedDate}</td>
                <td>$ {""}{amount}</td>
                <td>{statusBadge}</td>
        
              </tr>
            );
          })}
        </tbody>):(
          <p>No Transaction Found</p>
        )}
      </table>
    </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Chat</h4>
                  <div className="chat-conversation">
                    <SimpleBar style={{ height: "365px" }}>
                      <ul
                        className="conversation-list"
                        data-simplebar
                        style={{ maxHeight: "367px" }}
                      >
                        <li className="clearfix">
                          <div className="chat-avatar">
                            <img
                              src={user2}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:00</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">John Deo</span>
                              <p>Hello!</p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix odd">
                          <div className="chat-avatar">
                            <img
                              src={user3}
                              className="avatar-xs rounded-circle"
                              alt="Female"
                            />
                            <span className="time">10:01</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">Smith</span>
                              <p>
                                Hi, How are you? What about our next meeting?
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix">
                          <div className="chat-avatar">
                            <img
                              src={user2}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:04</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">John Deo</span>
                              <p>Yeah everything is fine</p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix odd">
                          <div className="chat-avatar">
                            <img
                              src={user3}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:05</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name">Smith</span>
                              <p>Wow that &aposs great</p>
                            </div>
                          </div>
                        </li>
                        <li className="clearfix odd">
                          <div className="chat-avatar">
                            <img
                              src={user3}
                              className="avatar-xs rounded-circle"
                              alt="male"
                            />
                            <span className="time">10:08</span>
                          </div>
                          <div className="conversation-text">
                            <div className="ctext-wrap">
                              <span className="user-name mb-2">Smith</span>

                              <img
                                src={smimg1}
                                alt=""
                                height="48"
                                className="rounded me-2"
                              />
                              <img
                                src={smimg2}
                                alt=""
                                height="48"
                                className="rounded"
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </SimpleBar>

                    <Row className="mt-3 pt-1">
                      <Col md="9" className="chat-inputbar col-8">
                        <Input
                          type="text"
                          className="chat-input"
                          placeholder="Enter your text"
                        />
                      </Col>
                      <Col md="3" className="chat-send col-4">
                        <div className="d-grid">
                          <Button
                            type="submit"
                            color="success"
                            className="btn-block"
                          >
                            Send
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
