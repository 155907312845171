import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
} from "reactstrap"
import classnames from "classnames"
import General from "components/general/General"
import FeatureApartments from "components/featureApartments/FeatureApartments"
import { useNavigate, useParams } from "react-router-dom"

import "./StyleTable.scss"

import { get, post } from "helpers/api_helper"
import Swal from "sweetalert2"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import MediaApartments from "components/mediaApartments/MediaApartments"

import EditerComponent from "components/editor/Editer"

const Apartments = () => {
  const [activeTab, setActiveTab] = useState("1")
  const [addApartment, setAddApartment] = useState(false)
  const [formData, setFormData] = useState({
    apartment_type: "Entire",
  })

  const navigate = useNavigate()

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const { id } = useParams()

  const onChangeHandlerFn = data => {
    setFormData(prevData => ({
      ...prevData,
      [data?.name]: data?.value,
    }))

    submitHandler()
  }

  const onInputChange = e => {
    const { name, value } = e.target || {} // Safeguard against undefined e.target

    if (name === "neighbourhood") {
      // Handle neighbourhood input
      setFormData(prevValues => ({
        ...prevValues,
        neighbourhood: value,
      }))
    } else {
      // Handle other fields
      setFormData(prevValues => ({
        ...prevValues,
        [name]: value,
      }))
    }
  }

  const onAddressChange = evt => {
    const { name, value } = evt.target

    setFormData(prevData => ({
      ...prevData,
      address: {
        ...prevData.address, // Preserve existing fields in address
        [name.split(".")[1]]: value, // Update only the relevant field (city or street)
      },
    }))
  }

  const submitHandler = () => {
    const token = authHeader()

    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",

        Authorization: token,
      },
    }
    const url = id ? `/apartments/${id}` : "/apartments"

    post(url, formData, config)
      .then(res => {

        Swal.fire({
          position: "center",
          icon: "success",
          title: res?.message || "Apartment created successfully with media",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          setAddApartment(!addApartment)
          navigate("/apartments")
        })
      })
      .catch(error => {
        console.log("errrrrrrrrrrrr", error)
        Swal.fire({
          position: "center",
          icon: "error",
       
          text:
            error.message || "An error occurred while updating the apartment",
          showConfirmButton: true,
        })
      })
  }
  const fetchApartmentsById = async () => {
    try {
      const token = authHeader() // Fetch the authorization token

      const config = {
        headers: {
          Authorization: token,
        },
      }

      const res = await get(`/apartments/${id}`, config)

      setFormData(res?.data)
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    if (id) {
      fetchApartmentsById()
    }
  }, [])

  document.title = "Apartments |  SHS  Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Apartments"
            breadcrumbItem={"Manage"}
            link="apartments" 

          />

          <Row>
            <Col className="col-12">
              <Col
                className="col-12 d-flex justify-content-end mt-3 me-3"
                style={{ paddingRight: "20px" }}
              ></Col>
              <Card style={{ padding: "20px" }}>
                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggle("1")}
                        >
                          GENERAL
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => toggle("2")}
                        >
                          FEATURES
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => toggle("3")}
                        >
                          MEDIA
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => toggle("4")}
                        >
                          OTHERS
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <General
                      values={formData}
                      onInputChange={onInputChange}
                      onAddressChange={onAddressChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <FeatureApartments
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>{" "}
                  <TabPane tabId="3">
                    <MediaApartments
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <EditerComponent
                      values={formData}
                      onInputChange={onInputChange}
                      onChangeHandlerFn={onChangeHandlerFn}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Apartments
