import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
} from "reactstrap"
import classnames from "classnames"
import RoomTypeCompo from "components/roomtype/RoomTypeCompo"
import { useNavigate, useParams } from "react-router-dom"
import { get, post } from "helpers/api_helper"
import Swal from "sweetalert2"
import authHeader from "helpers/jwt-token-access/auth-token-header"

const ManageRoomType = () => {
  const [activeTab, setActiveTab] = useState("1")
  const [formData, setFormData] = useState({
    roomtype: "",
    description: "",
  })

  const { id } = useParams()

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const navigate = useNavigate()
  const onInputChange = e => {
    const { name, value } = e.target // Safeguard against undefined e.target

    // Handle other fields
    setFormData(prevValues => ({
      ...prevValues,
      [name]: value,
    }))
  }

  const fetchRoomById = async () => {
    try {
      const token = authHeader()
      const config = { headers: { Authorization: token } }
      const res = await get(`/roomtypes/${id}`, config)

      if (res?.data) {
        setFormData({
          roomtype: res.data.roomtype,
          description: res.data.description,
        })
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSubmit = async () => {
    const { roomtype, description } = formData

    const payload = {
      roomtype,
      description,
    }

    const token = authHeader()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const url = id ? `/roomtype/${id}` : "/roomtype"
      const res = await post(url, payload, config)
      Swal.fire({
        position: "center",
        icon: "success",
        title: res?.message || "Room type  created successfully",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/settings/room-type")
        // setFormData({ role_name: "", description: "", selectedPermissions: {} });
      })
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Error creating role",
        text: error.response?.data?.message || "An unexpected error occurred",
        showConfirmButton: true,
      })
    }
  }

  useEffect(() => {
    if (id) {
      fetchRoomById()
    }
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Room Type"
            breadcrumbItem={"Manage"}
            link="settings/room-type"
          />

          <Row>
            <Col className="col-12">
              <Card style={{ padding: "20px" }}>
                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggle("1")}
                        >
                          ROOM TYPE
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <RoomTypeCompo
                      values={formData}
                      onInputChange={onInputChange}
                      onSubmit={handleSubmit}
                    />
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManageRoomType
