import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import PropTypes from "prop-types";

const featureList = [
  "Air Condition", "TV", "Kitchen", "Washer", "Doorman",
  "Oven", "Toaster", "Coffee Machine", "Hot Water",
  "Wi-Fi", "Fridge", "Iron", "Terrace", "Microwave", "Dishwasher",
  "Blender", "Alarm System", "Sofa", "Bed"
];

const ServiceRooms = ({ values, onInputChange, setActiveTab }) => {
  const [services, setServices] = useState([]);
  const [showAddField, setShowAddField] = useState(false);
  const [newServiceName, setNewServiceName] = useState("");

  // Initialize services state based on existing values
  useEffect(() => {
    const initialServices = featureList.map(feature => ({
      id: feature,
      name: feature,
      checked: values.services ? values.services.includes(feature) : false
    }));
    setServices(initialServices);
  }, [values.services]);

  // Function to handle new service name input change
  const handleNewServiceChange = (event) => {
    setNewServiceName(event.target.value);
  };

  // Function to add a new service
  const addNewService = () => {
    if (newServiceName && !services.some(service => service.name === newServiceName)) {
      const newServices = [...services, { id: newServiceName, name: newServiceName, checked: false }];
      setServices(newServices);
      setNewServiceName(""); // Reset input field
      setShowAddField(false); // Hide input field after adding

      // Pass array directly, no JSON.stringify
      onInputChange({ target: { name: 'services', value: newServices.filter(service => service.checked).map(service => service.name) } });
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (index) => {
    const newServices = [...services];
    newServices[index].checked = !newServices[index].checked;
    setServices(newServices);

    // Pass array directly, no JSON.stringify
    onInputChange({ target: { name: 'services', value: newServices.filter(service => service.checked).map(service => service.name) } });
  };

  // Function to group services into rows
  const groupServicesIntoRows = (services, itemsPerRow) => {
    const rows = [];
    for (let i = 0; i < services.length; i += itemsPerRow) {
      rows.push(services.slice(i, i + itemsPerRow));
    }
    return rows;
  };

  const rows = groupServicesIntoRows(services, 3); // 3 items per row

  return (
    <div>
      <FormGroup>
        <Label for="service-features">Available Services</Label>
        {rows.map((row, rowIndex) => (
          <Row key={rowIndex} className="mb-2">
            {row.map((service) => (
              <Col md={4} key={service.id} className="d-flex align-items-center">
                <FormGroup check inline>
                  <Label check className="form-check-label">
                    <Input
                      type="checkbox"
                      id={`service-check-${service.id}`}
                      checked={service.checked}
                      onChange={() => handleCheckboxChange(services.indexOf(service))}
                    />
                    {' '}
                    {service.name}
                  </Label>
                </FormGroup>
              </Col>
            ))}
          </Row>
        ))}
      </FormGroup>

      {/* <Button color="primary" onClick={() => setShowAddField(true)}>
        + Add More Service
      </Button> */}

      {showAddField && (
        <div>
          <FormGroup>
            <Label for="new-service-name">New Service Name</Label>
            <Input
              type="text"
              id="new-service-name"
              value={newServiceName}
              onChange={handleNewServiceChange}
              placeholder="Enter new service name"
            />
          </FormGroup>
          <Button color="success" onClick={addNewService}>
            Add Service
          </Button>
        </div>
      )}

      <Row style={{ marginBottom: "25px" ,marginTop:"25px"}}>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("1")}>Back</Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={(()=>{
            setActiveTab("3")
          })}>Next</Button>
        </Col>
      </Row>
    </div>
  );
};

ServiceRooms.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default ServiceRooms;
