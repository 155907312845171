import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Row, FormGroup, Label, Col, Input, Button } from "reactstrap";
import { hasPermission } from "helpers/validateToken";
import { useNavigate, useParams } from "react-router-dom";

// Permission options and levels (could be dynamic or static as per your requirement)
const permissionsOptions = [
  { value: 'Dashboard', label: 'Dashboard' },
  { value: 'Apartments', label: 'Apartments' },
  { value: 'Residence', label: 'Residence' },
  { value: 'Rooms', label: 'Rooms' },

  { value: 'Bookings', label: 'Bookings' },
  { value: 'Settings', label: 'Settings' },




  { value: 'Users', label: 'Users' },
  { value: 'Role', label: 'Role' },

 
  { value: 'AddRoomtype', label: 'Add Room Type' },
  { value: 'AddSlot', label: 'Add Slot' },
  { value: 'AddNeighbourhood', label: 'Add Neighbourhood' },
];

const permissionLevels = ['No-access', 'Read-only', 'Read-write'];

const AddRole = ({ values = { selectedPermissions: {} }, onInputChange, onSubmit }) => {
  // Parse selectedPermissions if it's a string
  const [selectedPermissions, setSelectedPermissions] = useState(
    typeof values?.selectedPermissions === 'string'
      ? JSON.parse(values.selectedPermissions)
      : values?.selectedPermissions || {}
  );

  const [isPermission, setIsPermission] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  // Function to determine if a checkbox should be checked
  const isPermissionChecked = (permValue, level) => {
    // Check if the selected permission level matches the current level
    return selectedPermissions[permValue] === level;
  };

  // Handle checkbox change
  const handleRoleChange = (event) => {
    const permission = event.target.getAttribute('data-permission');
    const level = event.target.getAttribute('data-level');

    // Update the selected permission with the new level
    const updatedPermissions = {
      ...selectedPermissions,
      [permission]: level,
    };

    setSelectedPermissions(updatedPermissions);

    // Call the parent handler
    onInputChange({ target: { name: 'selectedPermissions', value: updatedPermissions } });
  };

  useEffect(() => {
    // Get permissions and user role from localStorage
    const obj = JSON.parse(localStorage.getItem("permission"));
    const permissions = JSON.parse(obj?.permission);

    // Assuming the 'role' is stored in localStorage or somewhere in the user context
    const userRole = obj?.role_name;

    // Check if the user is Admin
    const isAdmin = userRole === "Admin";

    // Check if the user has the permission to access 'role'
    const hasPerm = hasPermission(
      permissions,
      "role",
      ["Read-only", "Read-write"],
      isAdmin
    );

    // Handle permission checks and redirect if necessary
    if (isAdmin) {
      setIsPermission(true); // Admin has access to everything
    } else if (!hasPerm) {
      navigate("/pages-404"); // Redirect if no permission
      setIsPermission(false); // Set permission state to false
    } else {
      const role = permissions["role"];
      if (role === "Read-only") {
        setIsPermission(false); // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true); // Full access
      } else {
        setIsPermission(false); // Default case
      }
    }

    // Handle setting selected permissions if pre-existing values exist
    if (values?.selectedPermissions) {
      setSelectedPermissions(
        typeof values.selectedPermissions === 'string'
          ? JSON.parse(values.selectedPermissions) // If it's a string, parse it
          : values.selectedPermissions // Otherwise, use it directly
      );
    }

  }, [values?.selectedPermissions, navigate]);

  return (
    <Col className="col-12">
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label for="role_name">
              Role Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="role_name"
              type="text"
              id="role_name"
              placeholder="Enter role name"
              value={values?.role_name || ""}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>
      </Row>

      {/* Permission Checkboxes */}
      <Row>
        {permissionsOptions.map((perm) => (
          <Col md={4} key={perm.value}>
            <FormGroup>
              <Label>{perm.label}</Label>
              {permissionLevels.map((level) => (
                <div key={level} className="form-check">
                  <Input
                    className="form-check-input"
                    name={perm.value}
                    type="checkbox" // Use checkbox for mutually exclusive options
                    id={`${perm.value}-${level}`}
                    data-permission={perm.value} // Custom data attribute for the permission key
                    data-level={level} // Custom data attribute for the permission level
                    checked={isPermissionChecked(perm.value, level)}
                    onChange={handleRoleChange} // Handle checkbox change
                  />
                  <Label className="form-check-label" htmlFor={`${perm.value}-${level}`}>
                    {level}
                  </Label>
                </div>
              ))}
            </FormGroup>
          </Col>
        ))}
      </Row>

      <Row className="mt-4">
        <Col md={6}></Col>
        {isPermission && (
          <Col md={6} className="d-flex justify-content-end">
            <Button color="primary" onClick={onSubmit}>
              {id ? "Update" : "Submit"}
            </Button>
          </Col>
        )}
      </Row>
    </Col>
  );
};

// Add PropTypes validation
AddRole.propTypes = {
  values: PropTypes.shape({
    role_name: PropTypes.string,
    description: PropTypes.string,
    selectedPermissions: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddRole;
