import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { get } from "helpers/api_helper";
import authHeader from "helpers/jwt-token-access/auth-token-header";

const General = ({ values, onInputChange, onAddressChange, setActiveTab }) => {
  // State for validation errors
  const [errors, setErrors] = useState({});
  const [allUser, setAllUser] = useState();
  const [allSlot, setAllSlot] = useState();
  // const [allNeighborhood, setAllNeighborhood] = useState()

  const navigate = useNavigate();
  // Validation function
  const validate = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "owner_id",
      // "neighborhood",
      "address.city",
      "address.street",
      "zipcode",
      "price",
      "deposit",
      "slots_taken",
      "apartment_type",
      "active",
      "booking_status",
    ];

    requiredFields.forEach((field) => {
      const value = field
        .split(".")
        .reduce((acc, part) => acc && acc[part], values);
      if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Next button click
  const handleNext = () => {
    if (validate()) {
      setActiveTab("2");
    }
  };
  const getAllUser = async () => {
    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const res = await get(`/users`, config);

      setAllUser(res.data);
    } catch (error) {
      console.log("an error occured", error);
    }
  };
  const getAllSlot = async () => {
    const token = authHeader();
    const config = {
      headers: {
        Authorization: token,
      },
    };

    try {
      const res = await get(`/slots`, config);

      setAllSlot(res.data);
    } catch (error) {
      console.log("an error occured", error);
    }
  };

  // Filter and format the slot options based on the current date

  useEffect(() => {
    // getting  for the all user and  render in the field of the  owner//
    getAllUser();
    // getting for the all slot and render in the field in the  slot //
    getAllSlot();
  }, []);

  return (
    <div>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="name">
              Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="name"
              type="text"
              id="name"
              placeholder="Enter Name"
              onChange={onInputChange}
              value={values?.name || ""}
              invalid={!!errors.name}
            />
            {errors.name && (
              <FormFeedback style={{ color: "red" }}>
                {errors.name}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="owner_id">
              Owner Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="owner_id"
              type="select"
              id="owner_id"
              placeholder="Enter owner id"
              onChange={onInputChange}
              value={values?.owner_id || ""}
              invalid={!!errors.owner_id}
            >
              {/* "Select Role" is shown only when no role is selected */}
              {!values?.role && <option value="">Select Name</option>}

              {allUser?.map((item) => {
                return (
     
                    <option  key={item?.id} value={item?.id}>
                      {item?.first_name} {item?.last_name}
                    </option>
              
                );
              })}
            </Input>
            {errors.owner_id && (
              <FormFeedback style={{ color: "red" }}>
                {errors.owner_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="addressCity">
              City <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="address.city"
              type="text"
              id="addressCity"
              placeholder="Enter City"
              value={values?.address?.city || ""}
              onChange={onAddressChange}
              invalid={!!errors["address.city"]}
            />
            {errors["address.city"] && (
              <FormFeedback style={{ color: "red" }}>
                {errors["address.city"]}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="addressStreet">
              Street <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="address.street"
              type="text"
              id="addressStreet"
              placeholder="Enter Street"
              value={values?.address?.street || ""}
              onChange={onAddressChange}
              invalid={!!errors["address.street"]}
            />
            {errors["address.street"] && (
              <FormFeedback style={{ color: "red" }}>
                {errors["address.street"]}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label for="zipcode">
              Zipcode <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="zipcode"
              type="text"
              id="zipcode"
              placeholder="Enter Zipcode"
              onChange={onInputChange}
              value={values?.zipcode || ""}
              invalid={!!errors.zipcode}
            />
            {errors.zipcode && (
              <FormFeedback style={{ color: "red" }}>
                {errors.zipcode}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="price">
              Price <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="price"
              type="number"
              id="price"
              placeholder="Enter Price"
              onChange={onInputChange}
              value={values?.price || ""}
              invalid={!!errors.price}
            />
            {errors.price && (
              <FormFeedback style={{ color: "red" }}>
                {errors.price}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="deposit">
              Deposit <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="deposit"
              type="number"
              id="deposit"
              placeholder="Enter Deposit"
              onChange={onInputChange}
              value={values?.deposit || ""}
              invalid={!!errors.deposit}
            />
            {errors.deposit && (
              <FormFeedback style={{ color: "red" }}>
                {errors.deposit}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="slots_taken">
              Slots Taken <span style={{ color: "red" }}>*</span>
            </Label>

            <Input
              type="select"
              id="slots_taken"
              name="slots_taken"
              onChange={onInputChange}
              value={values?.slots_taken || ""}
              invalid={!!errors.slots_taken}
            >
              {/* "Select Slots" is shown only when no role is selected */}
              {!values?.slots_taken && <option value="">Select Slot</option>}

              {allSlot?.map((item) => {
            
                return (
              
                    <option  key={item?.id} value={item?.id}>
                      {item?.slot}
                    </option>
          
                );
              })}
            </Input>

            {errors.slots_taken && (
              <FormFeedback style={{ color: "red" }}>
                {errors.slots_taken}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="active">
              Published <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="select"
              name="active"
              id="active"
              onChange={onInputChange}
              value={values?.active !== undefined ? String(values.active) : ""}
              invalid={!!errors.active}
            >
              <option value="">Select published type</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </Input>
            {errors.active && (
              <FormFeedback style={{ color: "red" }}>
                {errors.active}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="slots_taken">
              Availability <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              type="select"
              name="booking_status"
              id="booking_status"
              onChange={onInputChange}
              value={values?.booking_status || ""}
              invalid={!!errors.booking_status}
            >
              <option value="">Select availability</option>
              <option value="Available">Available</option>
              <option value="Unavailable">Unavailable</option>
           
            </Input>
            {errors.booking_status && (
              <FormFeedback style={{ color: "red" }}>
                {errors.booking_status}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Button color="secondary" onClick={() => navigate("/apartments")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

// Define PropTypes
General.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default General;
