
// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";

export function ValidateUser(navigate){
    const obj = JSON.parse(localStorage.getItem("authUser"))
 
    if (!obj){
        navigate('/logout')
    }else if (!validateToken(obj.token)){
        navigate('/logout')
    }
}
function validateToken(accessToken){
    var isExpired = false;

    if (accessToken){
      let token = jwtDecode(accessToken);
      let currentDate = new Date();

    //   console.log(token)
    //   JWT exp is in seconds
      if (token.exp * 1000 < currentDate.getTime()) {
        isExpired = false;
      } else {
        isExpired = true;
      }
    }
   
    return isExpired
  }


  export function hasPermission(permissions, conponent, permission_grp,isAdmin){
      //  const obj = JSON.parse(localStorage.getItem("permission"))
   
      //   let check = obj?.role_name
       
   
    if(isAdmin === "Admin"){
      return true
    }


    // if (isAdmin =='Admin') {
    //   return true;
    // }

    let role = permissions[conponent]     
  
    return permission_grp?.includes(role)
  }

