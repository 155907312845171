import PropTypes from 'prop-types';
import React, {  } from "react"
import { Routes, Route } from 'react-router-dom';
import { connect } from "react-redux";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import UserProfile from "pages/Authentication/user-profile"
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"
import Login1 from "pages/AuthenticationInner/Login"
import Login2 from "pages/AuthenticationInner/Login2"
import Register1 from "pages/AuthenticationInner/Register"
import Register2 from "pages/AuthenticationInner/Register2"
import Recoverpw from "pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "pages/AuthenticationInner/ForgetPassword"
import LockScreen from "pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "pages/AuthenticationInner/auth-two-step-verification-2"
import Dashboard from "pages/Dashboard/index"
import PagesStarter from "pages/Utility/pages-starter"
import PagesMaintenance from "pages/Utility/pages-maintenance"
import PagesComingsoon from "pages/Utility/pages-comingsoon"
import PagesTimeline from "pages/Utility/pages-timeline"
import PagesInvoice from "pages/Utility/PagesInvoice"
import PagesFaqs from "pages/Utility/pages-faqs"
import PagesPricing from "pages/Utility/pages-pricing"
import Pages404 from "pages/Utility/pages-404"
import Pages500 from "pages/Utility/pages-500"
import PagesGallery from "pages/Utility/PagesGallery"
import PagesDirectory from "pages/Utility/PagesDirectory"
import PagesProfile from "pages/Utility/pages-profile"
import Apartments from "pages/Apartments/list"
import ManageApartment from "pages/Apartments/manage"

import Invoice from "pages/invoice/Invoice"
import Admin from "pages/admin/Admin"
import Bookings from "pages/bookings/Bookings"
import BookingsDetail from "pages/bookings/Bookingsdetail"
import User from "pages/user/Userslisting"
// import Rooms from "pages/rooms/Rooms"

import Residence from "pages/residenceis/Residencelistig"
import ManageUser from "pages/user/ManageUser"
// import AddRole from "components/addrole/AddRole"
import AddRolslisting from "pages/addRols/AddRolslisting"
import ResidenceManage from 'pages/residenceis/ResidenceManage';

import RoleManager from 'pages/addRols/RoleManage';
import ManageRooms from 'pages/rooms/ManageRooms';
import RoomType from"pages/manageAbleRoomType/RoomType"
import ManageRoomType from "pages/manageAbleRoomType/ManageRoomType"
import Slots from 'pages/manageAbleSlots/Slots';
import ManageSlots from 'pages/manageAbleSlots/ManageSlots';
import NeighourhoodListing from 'pages/manageAbleNeighborhood/NeighourhoodListing';
import ManageNeighorhood from 'pages/manageAbleNeighborhood/ManageNeighorhood';


const Home = () => {

 
 


 




  const userRoutes = [
    { path: "/dashboard", component: <Dashboard /> },
    { path: "/settings/role", component: <AddRolslisting /> },
    { path: "/settings/role/add", component: <RoleManager /> },
    { path: "/settings/role/edit/:id", component: <RoleManager /> },
   { path: "/profile", component: <UserProfile /> },
   { path: "/apartments", component: <Apartments /> },
   { path: "/apartments/add", component: <ManageApartment /> },
   { path: "/apartments/edit/:id", component: <ManageApartment /> },
  


  { path: "/settings/users", component: <User /> },
  { path: "/settings/users/add", component: <ManageUser/> ,  },
  { path: "/settings/users/edit/:id", component: <ManageUser/>},
  { path: "/invoice", component: <Invoice />  },
    { path: "/bookings", component: <Bookings /> },
    { path: "/booking/:id", component: <BookingsDetail /> },

    
    // { path: "/rooms", component: <Rooms />, },
    { path: "/residence/rooms/add", component: <ManageRooms />, },
    { path: "/residence/rooms/edit/:id", component: <ManageRooms />, },

    { path: "/residence", component: <Residence />,  },
    { path: "/residence/add", component: <ResidenceManage />,  },
    { path: "/residence/edit/:id", component: <ResidenceManage />},
    { path: "/settings/room-type", component: <RoomType/>,  },
    { path: "/settings/room-type/add", component: <ManageRoomType />,  },
    { path: "/settings/room-type/edit/:id", component: <ManageRoomType />},
    { path: "/settings/slots", component: <Slots/>,  },
    { path: "/settings/slots/add", component: <ManageSlots />,  },
    { path: "/settings/slots/edit/:id", component: <ManageSlots />},
    { path: "/settings/neighbourhood", component: <NeighourhoodListing/>,  },
    { path: "/settings/neighbourhood/add", component: <ManageNeighorhood />,  },
    { path: "/settings/neighbourhood/edit/:id", component: <ManageNeighorhood />},

  { path: "/admin", component: <Admin /> },
  { path: "/pages-starter", component: <PagesStarter /> },
    { path: "/pages-timeline", component: <PagesTimeline /> },
    { path: "/pages-invoice", component: <PagesInvoice /> },
    { path: "/pages-directory", component: <PagesDirectory /> },
    { path: "/pages-faqs", component: <PagesFaqs /> },
    { path: "/pages-pricing", component: <PagesPricing /> },
    { path: "/pages-gallery", component: <PagesGallery /> },
    { path: "/pages-profile", component: <PagesProfile /> },
  
    // this route should be at the end of all other routes
    { path: "/", component: <Dashboard /> },
  ]

  const authRoutes = [
    { path: "/logout", component: <Logout />,  },
    { path: "/login", component: <Login />,  },
    { path: "/forgot-password", component: <ForgetPwd />  },
    { path: "/register", component: <Register /> },
  { path: "/pages-maintenance", component: <PagesMaintenance /> },
    { path: "/pages-comingsoon", component: <PagesComingsoon /> },
    { path: "/pages-404", component: <Pages404 /> },
    { path: "/pages-500", component: <Pages500 /> },
 { path: "/pages-login", component: <Login1 /> },
    { path: "/pages-login-2", component: <Login2 /> },
    { path: "/pages-register", component: <Register1 /> },
    { path: "/pages-register-2", component: <Register2 /> },
    { path: "/page-recoverpw", component: <Recoverpw /> },
    { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
    { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
    { path: "/auth-lock-screen", component: <LockScreen /> },
    { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
    { path: "/page-confirm-mail", component: <ConfirmMail /> },
    { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
    { path: "/auth-email-verification", component: <EmailVerification /> },
    { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
    { path: "/auth-two-step-verification", component: <TwostepVerification /> },
    {
      path: "/auth-two-step-verification-2",
      component: <TwostepVerification2 />,
    },
  ]

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
            path={`${route.path}`}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route >
          {userRoutes.map((route, idx) => {
        
            return(
              <Route
                path={`${route.path}`}
                element={
                  <Authmiddleware requiredRole={route.requiredRole}>
                    {route.component}
                  </Authmiddleware>}
                key={idx}
                exact={true}
              />
            )
          })}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

Home.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(Home);
