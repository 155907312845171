import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
} from "reactstrap"
import classnames from "classnames"
import UserGeneral from "components/users/UserGeneral"

import { useNavigate, useParams } from "react-router-dom"

// import "./StyleTable.scss";

import { get, post } from "helpers/api_helper"
import Swal from "sweetalert2"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"

const ManageUser = () => {
  const [activeTab, setActiveTab] = useState("1")
  const [formData, setFormData] = useState({})

  const navigate = useNavigate()

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const { id } = useParams()

  const onChangeHandlerFn = data => {
    setFormData(prevData => ({
      ...prevData,
      [data?.name]: data?.value,
    }))

    submitHandler()
  }

  const onInputChange = evt => {
    setFormData(prevData => ({
      ...prevData,
      [evt.target.name]: evt.target.value,
    }))
  }

  const submitHandler = () => {
    const token = authHeader()

    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",

        Authorization: token,
      },
    }
    const url = id ? `/users/${id}` : "/users/signup"

    post(url, formData, config)
      .then(res => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: res?.message || "Apartment created successfully with media",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          navigate("/settings/users")
        })
      })
      .catch(error => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Error updating  user ",
          text: error.response?.data,
          showConfirmButton: true,
        })
      })
  }
  const fetchUserById = async () => {
    try {
      const token = authHeader() // Fetch the authorization token

      const config = {
        headers: {
          Authorization: token,
        },
      }

      const res = await get(`/users/${id}`, config)

      setFormData(res)
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (id) {
      fetchUserById()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Users"
            breadcrumbItem={"Manage"}
            link="settings/users"
          />

          <Row>
            <Col className="col-12">
              <Card style={{ padding: "20px" }}>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                ></Col>

                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggle("1")}
                        >
                          USER
                        </NavLink>
                      </NavItem>
                      <NavItem></NavItem>
                      <NavItem></NavItem>
                      <NavItem></NavItem>
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <UserGeneral
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                      onChangeHandlerFn={onChangeHandlerFn}
                    />
                  </TabPane>

              
                </TabContent>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                ></Col>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManageUser
