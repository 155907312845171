import React, { useEffect, useState } from "react"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import PropTypes from "prop-types";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit"

import Swal from "sweetalert2"
import { get, del ,post} from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { useNavigate,useParams } from "react-router-dom"
// import { hasPermission } from "helpers/validateToken"
import { hasPermission } from "helpers/validateToken"
import ToggleButton from "components/togglebutton/ToggleButton"
import Loader from "components/loader/Loader";
// import ToggleButton from "components/togglebutton/ToggleButton"

const Rooms = ({values}) => {

  const [filterType, setFilterType] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [selectedRows, setSelectedRows] = useState({})
  const [limit, setLimit] = useState(5)
  const [sortBY, setSortBY] = useState("id")
  const [order, setOrder] = useState("ASC")
  const [isPermission, setIsPermission] = useState(false)
  const [loading, setLoading] = useState(true);

  const { id } = useParams()
 const residenceID = JSON.parse(localStorage.getItem("residenceId"))

 console.log("residenceIDresidenceID",residenceID)


  const navigate = useNavigate()
  const [data, setData] = useState({
    columns: [
      {
        label: "ID",
        field: "idd",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("idd"),
      },
  
      {
        label: "ROOM NO.",
        field: "number",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("number"),
      },
      {
        label: "NAME",
        field: "residence",
        sort: "asc",
        width: 300,
        onClick: () => handleSort("residence_id"),
      },

      {
        label: "ROOM TYPE",
        field: "room_type",
        sort: "asc",
        width: 300,
        onClick: () => handleSort("room_type"),
      },

      {
        label: "PRICE",
        field: "price",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("price"),
      },
      {
        label: "PUBLISHED",
        field: "publishedIcon",
        sort: "disabled",
        width: 100,
        onClick: () => handleSort("name"),
      },
  
    ],
    rows: [],
  })

  const filterData = () => {
    return data.rows.filter(row => {
      const value = row[filterType] || ""
      switch (filterType) {
        case "id":
        case "name":
        case "room_type":
        case "residence":
        case "city":
          return value
            .toString()
            .toLowerCase()
            .includes(filterValue.toString().toLowerCase())
        default:
          return true
      }
    })
  }
  const handleCheckboxChange = id => {
    setSelectedRows(prevSelected => ({
      ...prevSelected,
      [id]: !prevSelected[id], // Toggle the specific row based on id
    }))
  }

  const filteredData = {
    columns: [
      ...(isPermission
        ? [
            {
              label: "",
              field: "select",
              sort: "disabled",
              attributes: {
                "data-attributes": "select",
              },
            },
          ]
        : []),

      ...data.columns,
    ],
    rows: filterData().map(row => ({
      ...row,
      select: isPermission ? (
        <Input
          type="checkbox"
          checked={!!selectedRows[row.id]}
          onChange={() => handleCheckboxChange(row.id)}
        />
      ) : null,
    })),
  }
  console.log("vlueeeedddd", typeof residenceID)


  const fetchRooms = async () => {
   
    try {
      const token = authHeader() // Fetch the authorization token

      const config = {
        headers: {
          Authorization: token,
        },
      }
      const finalResidenceID = residenceID === null ? "00000000" : residenceID;
      const res = await get(
        `viewRoombyResidence/${id ? values?.residence_id || id : finalResidenceID}`,
        config
      )

      // Log each item to the console
      const mappedRows = res.data.rows?.map(item => {
        return {
          ...item,
          publishedIcon:(
            <div>
             <ToggleButton
        isActive={item.publish === "true"}
        onToggle={() => handleToggle(item.id, item.publish === "true")}
      />
            </div>
          ),
          residence: (
            <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/residence/rooms/edit/${item.id}`)
            }}

              dangerouslySetInnerHTML={{
                __html: `
                <div style="color: #45c4ad;" >${item?.name}</div>
          
              `,
              }}
            />
          ),

          idd: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/residence/rooms/edit/${item.id}`)
              }}
              dangerouslySetInnerHTML={{
                __html: `
                <div  style="color: #45c4ad;">${item.id}</div>
     
              `,
              }}
            />
          ),
          user_email: item.owner?.user_email,

    
        }
      })

      setTotalPages(res?.pagination?.totalPages || 1)

      setData({
        columns: data.columns, // Keep existing columns
        rows: mappedRows || [], // Set mapped rows
      })
    } catch (error) {
      console.log("error", error)
    }finally{
      setLoading(false)
    }
  }
  const handleToggle = async (roomId, currentStatus) => {
    const token = authHeader() // Fetch the authorization token

    const config = {
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await post(`/updateRoom/${roomId}`, {
        publish: !currentStatus // Toggle publish status
      },config);

      if (response) {
        // Update your local state with the new data
        fetchRooms(currentPage, limit, sortBY, order); // Optionally, re-fetch the rooms to get updated data
      }
    } catch (error) {
      console.error("Error updating room:", error);
    }
  };

  const handleSort = field => {
    // const direction = sortBY === field && order === "ASC" ? "DESC" : "ASC";
    setSortBY(field)
    setOrder(prevOrder => (prevOrder === "ASC" ? "DESC" : "ASC"))
    fetchRooms(currentPage, limit, field, order)
  }
  const deleteRooms = async () => {
    const token = authHeader() // Fetch the authorization token
    const idsToDelete = Object.keys(selectedRows).filter(id => selectedRows[id])
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: token,
          },
        }

        try {
          const res = await Promise.all(
            idsToDelete.map(id => del(`/deleteRoom/${id}`, config))
          )

          Swal.fire({
            title: "Deleted!",
            text: res[0]?.message,
            icon: "success",
            timer: 3000, // 3 seconds timer
          })
          setSelectedRows({})
          fetchRooms(currentPage, limit, sortBY, order) // Refresh the data
        } catch (error) {
          console.log("An error occurred:", error)
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the apartment.",
            icon: "error",
          })
        }
      }
    })
  }
  useEffect(() => {
    // Get permissions and user role from localStorage
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)

    // Assuming the 'role' is stored in localStorage or somewhere in the user context
    const userRole = obj?.role_name

    // Check if the user is Admin
    const isAdmin = userRole === "Admin"

    // Check if the user has the permission to access 'apartments'
    const hasPerm = hasPermission(
      permissions,
      "Rooms",
      ["Read-only", "Read-write"],
      isAdmin
    )

    // If the user is Admin, they should have full access
    if (isAdmin) {
      setIsPermission(true) // Admin has access to everything
    } else if (!hasPerm) {
      navigate("/pages-404") // Redirect if no permission
      setIsPermission(false) // Set permission state to false
    } else {
      // Handle other permissions like 'Read-only' and 'Read-write'
      const role = permissions["Rooms"]
      if (role === "Read-only") {
        setIsPermission(false) // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true) // Full access
      } else {
        setIsPermission(false) // Default case
      }
    }
  }, [])
  const selectedCount = Object.keys(selectedRows).filter(id => selectedRows[id]).length;

  useEffect(() => {
    // setTimeout(()=>{
      fetchRooms(currentPage, limit, sortBY, order)
    // },5000)

  }, [currentPage, limit, sortBY, order])

  const handleTableChange = newPage => {
    setCurrentPage(newPage)
  }

  document.title = "Rooms | SHS Admin"

  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      <div>

        <div className="container-fluid">
          {/* <Breadcrumbs maintitle="home" title="rooms" breadcrumbItem={"list"} /> */}

          <Row>
            <Col className="col-12">
              <Card>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
                  {Object.keys(selectedRows).some(id => selectedRows[id]) ? (
                    <Button color="danger" onClick={deleteRooms}>
                      Delete Selected {selectedCount}
                    </Button>
                  ) : (
                    isPermission && (
                      <Button
                        color="primary"
                        onClick={() => navigate(`/residence/rooms/add`,{ state: { Fdata:values }})}

                     

                      >
                        Add Rooms
                      </Button>
                    )
                  )}
                </Col>
{     loading ? <Loader/>  :                         <CardBody>
                  <Row className="mb-3">
                    <Col md={2}>
                      {/* <FormGroup>
                        <Label for="filterType">Filter By</Label>
                        <Input
                          type="select"
                          id="filterType"
                          value={filterType}
                          onChange={e => setFilterType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="id">By ID</option>
                          <option value="name">By Name</option>
                          <option value="city">By City</option>
                          <option value="residence">By Residence</option>
                          <option value="room_type">By Room type</option>
                        </Input>
                      </FormGroup> */}
                    </Col>
                    {filterType && (
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterValue">Filter Value</Label>
                          <Input
                            type="text"
                            id="filterValue"
                            placeholder={`Enter ${filterType}`}
                            value={filterValue}
                            onChange={e => setFilterValue(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    searching={false}
                    data={filteredData}
                    paging={false}
                  />
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <FormGroup>
                      <Label for="limitSelect">Items per Page</Label>
                      <Input
                        type="select"
                        id="limitSelect"
                        value={limit}
                        onChange={e => {
                          setFilterType("")
                          setLimit(parseInt(e.target.value))
                          setCurrentPage(1) // Reset to the first page when limit changes
                        }}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </Input>
                    </FormGroup>
                    <MDBPagination className="mt-3">
                      <MDBPaginationItem disabled={currentPage === 1}>
                        <MDBPaginationLink
                          onClick={() => handleTableChange(currentPage - 1)}
                          tabIndex="-1"
                          aria-disabled={currentPage === 1}
                        >
                          Previous
                        </MDBPaginationLink>
                      </MDBPaginationItem>
                      {[...Array(totalPages).keys()].map(page => (
                        <MDBPaginationItem
                          key={page + 1}
                          active={currentPage === page + 1}
                        >
                          <MDBPaginationLink
                            onClick={() => handleTableChange(page + 1)}
                            style={{
                              marginLeft: "3px", // Adds space to the left
                              marginRight: "3px", // Adds space to the right
                            }}
                          >
                            {page + 1}
                          </MDBPaginationLink>
                        </MDBPaginationItem>
                      ))}
                      <MDBPaginationItem disabled={currentPage === totalPages}>
                        <MDBPaginationLink
                          onClick={() => handleTableChange(currentPage + 1)}
                          aria-disabled={currentPage === totalPages}
                        >
                          Next
                        </MDBPaginationLink>
                      </MDBPaginationItem>
                    </MDBPagination>
                  </div>
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}


Rooms.propTypes = {

  values: PropTypes.object.isRequired,
  onChangeHandlerFn: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,


};



export default Rooms
