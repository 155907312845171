import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit"
import "./StyleTable.scss"
import Swal from "sweetalert2"
import { get, del, post } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { useNavigate } from "react-router-dom"

import { hasPermission } from "helpers/validateToken"
import ToggleButton from "components/togglebutton/ToggleButton"
import Loader from "components/loader/Loader"

const Apartments = () => {
  const [filter, setFilter] = useState({
    type: "",
    value: "",
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [selectedRows, setSelectedRows] = useState({})
  const [limit, setLimit] = useState(5)
  const [sortBY, setSortBY] = useState("apartment_id")
  const [order, setOrder] = useState("DESC")
  const [isPermission, setIsPermission] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const [data, setData] = useState({
    columns: [
      {
        label: "ID",
        field: "idLink",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("apartment_id"),
      },

      {
        label: "EMAIL",
        field: "user_email",
        sort: "asc",
        width: 300,
        onClick: () => handleSort("user_email"),
      },
      {
        label: "INFORMATION",
        field: "information",
        sort: "asc",
        width: 270,
        onClick: () => handleSort("information"),
      },
      {
        label: "STREET",
        field: "street",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("neighborhood"),
      },
      {
        label: "PRICE",
        field: "price",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("price"),
      },
      {
        label: "PUBLISHED",
        field: "published",
        sort: "disabled",
        width: 100,
        onClick: () => handleSort("published"),
      },
    ],
    rows: [],
  })

  const filterData = () => {
    return data.rows.filter(row => {
      const value =
        filter.type === "city"
          ? row.address?.city || ""
          : row[filter.type] || "" // Check for nested properties
     
      return value
        .toString()
        .toLowerCase()
        .includes(filter.value.toString().toLowerCase())
    })
  }

  const handleCheckboxChange = apartment_id => {
    setSelectedRows(prevSelected => ({
      ...prevSelected,
      [apartment_id]: !prevSelected[apartment_id],
    }))
  }

  const filteredData = {
    columns: [
      ...(isPermission
        ? [
            {
              label: "",
              field: "select",
              sort: "disabled",
              attributes: {
                "data-attributes": "select",
              },
            },
          ]
        : []),

      ...data.columns,
    ],
    rows: filterData().map(row => ({
      ...row,
      select: isPermission ? (
        <Input
          type="checkbox"
          checked={!!selectedRows[row.apartment_id]}
          onChange={() => handleCheckboxChange(row.apartment_id)}
        />
      ) : null,
    })),
  }

  const fetchApartments = async (
    page,
    limit,
    sortBY,
    order,
    filterType,
    filterValue
  ) => {
    setLoading(true)
    try {
      const token = authHeader() // Fetch the authorization token
      const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
      const userId = user.user?.id
    

      const obj = JSON.parse(localStorage.getItem("permission"))
      const userRole = obj?.role_name

      // Check if the user is Admin
      const isAdmin = userRole === "Admin"
      console.log("isadmin",isAdmin)

      const config = {
        headers: {
          Authorization: token,
        },
      }

      // Start building the query parameters
      const params = new URLSearchParams({
        page,
        limit,
        sortBy: sortBY,
        order,
      })

      // Add filter parameters based on the filterType and filterValue
      if (filterType && filterValue) {
        params.append(filterType, filterValue)
      }
      if (!isAdmin) {
        params.append("owner_id", userId);
      }

      const res = await get(
        `/apartmentswithsearch?${params.toString()}`,
        config
      )

      // Log each item to the console
      const mappedRows = res.data?.map(item => {
        return {
          ...item,
          street: item.address?.street || "", // Handle cases where address might be null
          idLink: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/apartments/edit/${item.apartment_id}`)
              }}
              dangerouslySetInnerHTML={{
                __html: `
                  <div style="color: #45c4ad;">${item.apartment_id}</div>
                `,
              }}
            />
          ),
          information: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/apartments/edit/${item.apartment_id}`)
              }}
              dangerouslySetInnerHTML={{
                __html: `
                  <div style="color: #45c4ad;">${item.name} - ${
                  item.owner?.user_email
                }</div>
                  <div>Type: ${item.apartment_type}</div>
                  <div>Booking Status: 
                    <span style="color: ${
                      item.booking_status === "Available" ? "green" : "red"
                    };">
                      ${item.booking_status}
                    </span>
                  </div>
                `,
              }}
            />
          ),
          user_email: item.owner?.user_email,
          published: (
            <div>
              <ToggleButton
                isActive={item.active === true}
                onToggle={() =>
                  handleToggle(item.apartment_id, item.active === true)
                }
              />
            </div>
          ),
        }
      })

      setTotalPages(res?.pagination?.totalPages || 1)

      setData({
        columns: data.columns, // Keep existing columns
        rows: mappedRows || [], // Set mapped rows
      })
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  const handleToggle = async (apartmetId, currentStatus) => {
    const token = authHeader() // Fetch the authorization token

    const config = {
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await post(
        `/apartments/${apartmetId}`,
        {
          active: !currentStatus, // Toggle publish status
        },
        config
      )

      if (response) {
        // Update your local state with the new data
        fetchApartments(currentPage, limit, sortBY, order)
        // Optionally, re-fetch the rooms to get updated data
      }
    } catch (error) {
      console.error("Error updating room:", error)
    }
  }
  const handleSort = field => {
    // const direction = sortBY === field && order === "ASC" ? "DESC" : "ASC";
    setSortBY(field)
    setOrder(prevOrder => (prevOrder === "ASC" ? "DESC" : "ASC"))
    fetchApartments(currentPage, limit, field, order, filter.type, filter.value)
  }
  const deleteApartment = async () => {
    const token = authHeader() // Fetch the authorization token
    const idsToDelete = Object.keys(selectedRows).filter(id => selectedRows[id])
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: token,
          },
        }

        try {
          await Promise.all(
            idsToDelete.map(id => del(`/apartments/${id}`, config))
          )

          Swal.fire({
            title: "Deleted!",
            text: "Your apartment has been deleted.",
            icon: "success",
            timer: 3000, // 3 seconds timer
          })
          setSelectedRows({})
          fetchApartments(currentPage, limit, sortBY, order) // Refresh the data
        } catch (error) {
          console.log("An error occurred:", error)
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the apartment.",
            icon: "error",
          })
        }
      }
    })
  }
  const selectedCount = Object.keys(selectedRows).filter(
    id => selectedRows[id]
  ).length

  useEffect(() => {
    // Get permissions and user role from localStorage
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)

    // Assuming the 'role' is stored in localStorage or somewhere in the user context
    const userRole = obj?.role_name

    // Check if the user is Admin
    const isAdmin = userRole === "Admin"

    // Check if the user has the permission to access 'apartments'
    const hasPerm = hasPermission(
      permissions,
      "Apartments",
      ["Read-only", "Read-write"],
      isAdmin
    )

    // If the user is Admin, they should have full access
    if (isAdmin) {
      setIsPermission(true) // Admin has access to everything
    } else if (!hasPerm) {
      navigate("/pages-404") // Redirect if no permission
      setIsPermission(false) // Set permission state to false
    } else {
      // Handle other permissions like 'Read-only' and 'Read-write'
      const role = permissions["Apartments"]

      if (role === "Read-only") {
        setIsPermission(false) // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true) // Full access
      } else {
        setIsPermission(false) // Default case
      }
    }
  }, [])

  useEffect(() => {
    fetchApartments(
      currentPage,
      limit,
      sortBY,
      order,
      filter.type,
      filter.value
    )
  }, [currentPage, limit, sortBY, order, filter.value.length])

  const handleTableChange = newPage => {
    setCurrentPage(newPage)
  }

  document.title = "Apartments | SHS Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Apartments"
            breadcrumbItem={"List"}
            link="apartments"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
                  {Object.keys(selectedRows).some(id => selectedRows[id]) ? (
                    <Button color="danger" onClick={deleteApartment}>
                      Delete Selected {selectedCount}
                    </Button>
                  ) : (
                    isPermission && (
                      <Button
                        color="primary"
                        onClick={() => navigate(`/apartments/add`)}
                      >
                        Add Apartment
                      </Button>
                    )
                  )}
                </Col>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={2}>
                      <FormGroup>
                        <Label for="filterType">Filter By</Label>
                        <Input
                          type="select"
                          id="filterType"
                          value={filter.type}
                          onChange={e =>
                            setFilter({ type: e.target.value, value: "" })
                          }
                        >
                          <option value="">Select</option>
                          <option value="apartment_id">By ID</option>
                          <option value="name">By Name</option>
                          <option value="city">By City</option>
                          <option value="apartment_type">
                            By Apartment type
                          </option>
                          <option value="street">By Street</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    {filter.type && (
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterValue">Filter Value</Label>
                          <Input
                            type="text"
                            id="filterValue"
                            placeholder={`Enter ${filter.type}`}
                            value={filter.value}
                            onChange={e =>
                              setFilter({ ...filter, value: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <MDBDataTable
                        responsive
                        striped
                        bordered
                        searching={false}
                        data={filteredData}
                        paging={false}
                      />
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <FormGroup>
                          <Label for="limitSelect">Items per Page</Label>
                          <Input
                            type="select"
                            id="limitSelect"
                            value={limit}
                            onChange={e => {
                              setLimit(parseInt(e.target.value))
                              setCurrentPage(1) // Reset to the first page when limit changes
                            }}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </Input>
                        </FormGroup>
                        <MDBPagination className="mt-3">
                          <MDBPaginationItem disabled={currentPage === 1}>
                            <MDBPaginationLink
                              onClick={() => handleTableChange(currentPage - 1)}
                              tabIndex="-1"
                              aria-disabled={currentPage === 1}
                            >
                              Previous
                            </MDBPaginationLink>
                          </MDBPaginationItem>
                          {[...Array(totalPages).keys()].map(page => (
                            <MDBPaginationItem
                              key={page + 1}
                              active={currentPage === page + 1}
                            >
                              <MDBPaginationLink
                                onClick={() => handleTableChange(page + 1)}
                                style={{
                                  marginLeft: "3px", // Adds space to the left
                                  marginRight: "3px", // Adds space to the right
                                }}
                              >
                                {page + 1}
                              </MDBPaginationLink>
                            </MDBPaginationItem>
                          ))}
                          <MDBPaginationItem
                            disabled={currentPage === totalPages}
                          >
                            <MDBPaginationLink
                              onClick={() => handleTableChange(currentPage + 1)}
                              aria-disabled={currentPage === totalPages}
                            >
                              Next
                            </MDBPaginationLink>
                          </MDBPaginationItem>
                        </MDBPagination>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Apartments
