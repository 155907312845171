import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  FormFeedback,
  InputGroup,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css"; // You can change the theme based on your requirement
import moment from "moment";
import { hasPermission } from "helpers/validateToken";
import { useNavigate, useParams } from "react-router-dom";

const Slots = ({ values, onInputChange, onSubmit }) => {
  const [errors, setErrors] = useState({});
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [isPermission, setIsPermission] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  // Set default start date as September 1 and end date as May 31
  const defaultStartDate = moment("September 1", "MMMM D").toDate();
  const defaultEndDate = moment("May 31", "MMMM D").toDate();

  // Parse pre-existing date range string into individual Date objects
  useEffect(() => {
    if (values.slot) {
      const dateStrings = values.slot.split(" ");
      if (dateStrings.length === 4) {
        const startDate = moment(`${dateStrings[0]} ${dateStrings[1]}`, "MMMM D").toDate();
        const endDate = moment(`${dateStrings[2]} ${dateStrings[3]}`, "MMMM D").toDate();
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
      }
    } else {
      // Set default selected dates if no pre-existing slot value
      setSelectedStartDate(defaultStartDate);
      setSelectedEndDate(defaultEndDate);
    }
  }, [values.slot]);

  // Validation function
  const validate = () => {
    const newErrors = {};
    const requiredFields = ["slot"];

    requiredFields.forEach((field) => {
      const value = field.split(".").reduce((acc, part) => acc && acc[part], values);
      if (!value || (Array.isArray(value) && value.length === 0)) {
        newErrors[field] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submit
  const handleNext = () => {
    if (validate()) {
      onSubmit(); // Only submit if validation passes
    }
  };

  // Handle start date change
  const handleStartDateChange = (dates) => {
    const startDate = dates[0]; // Get the first date selected
    setSelectedStartDate(startDate); // Update state
    const endDate = selectedEndDate || defaultEndDate; // Use existing end date or default
    const slotName = generateSlotName(startDate, endDate); // Generate slot name
    onInputChange({ slot: slotName }); // Trigger onInputChange
  };

  // Handle end date change
  const handleEndDateChange = (dates) => {
    const endDate = dates[0]; // Get the first date selected
    setSelectedEndDate(endDate); // Update state
    const startDate = selectedStartDate || defaultStartDate; // Use existing start date or default
    const slotName = generateSlotName(startDate, endDate); // Generate slot name
    onInputChange({ slot: slotName }); // Trigger onInputChange
  };

  // Generate slot name based on start and end dates
  const generateSlotName = (startDate, endDate) => {
    const formattedStartDate = moment(startDate).format("MMMM D");
    const formattedEndDate = moment(endDate).format("MMMM D");
    return `${formattedStartDate} ${formattedEndDate}`; // Combine the start and end date into a slot name
  };

  // Handle permissions
  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("permission"));
    const permissions = JSON.parse(obj?.permission);
    const userRole = obj?.role_name;

    const isAdmin = userRole === "Admin";
    const hasPerm = hasPermission(permissions, "addSlot", ["Read-only", "Read-write"], isAdmin);

    if (isAdmin) {
      setIsPermission(true);
    } else if (!hasPerm) {
      navigate("/pages-404"); // Redirect if no permission
      setIsPermission(false);
    } else {
      const role = permissions["addSlot"];
      if (role === "Read-only") {
        setIsPermission(false); // Limited access
      } else if (role === "Read-write") {
        setIsPermission(true); // Full access
      } else {
        setIsPermission(false); // Default case
      }
    }
  }, []);

  return (
    <Col className="col-12">
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="startDate">
            Slot  Start  Date <span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup>
              <Flatpickr
                name="startDate"
                className="form-control d-block"
                placeholder="Select Start Date"
                options={{
                  dateFormat: "M-d", // Format the selected dates
                }}
                value={selectedStartDate ? [selectedStartDate] : [defaultStartDate]} // Flatpickr expects an array of dates
                onChange={handleStartDateChange} // Handle date change
              />
            </InputGroup>
            {errors.slot && (
              <FormFeedback style={{ color: "red" }}>
                {errors.slot}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="endDate">
           Slot  End Date <span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup>
              <Flatpickr
                name="endDate"
                className="form-control d-block"
                placeholder="Select End Date"
                options={{
                  dateFormat: "M-d", // Format the selected dates
                }}
                value={selectedEndDate ? [selectedEndDate] : [defaultEndDate]} // Flatpickr expects an array of dates
                onChange={handleEndDateChange} // Handle date change
              />
            </InputGroup>
            {errors.slot && (
              <FormFeedback style={{ color: "red" }}>
                {errors.slot}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}></Col>
        {isPermission && (
          <Col md={6} className="d-flex justify-content-end">
            <Button color="primary" onClick={handleNext}>
              {id ? "Update" : "Submit"}
            </Button>
          </Col>
        )}
      </Row>
    </Col>
  );
};

// Define PropTypes
Slots.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Slots;
