import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit"
import Swal from "sweetalert2"
import { get, del } from "helpers/api_helper"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import { useNavigate } from "react-router-dom"
import { hasPermission } from "helpers/validateToken"
import Loader from "components/loader/Loader"

const Bookings = () => {

  const [filter, setFilter] = useState({
    type: "",
    value: "",
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [selectedRows, setSelectedRows] = useState({})
  const [limit, setLimit] = useState(5)
  const [sortBY, setSortBY] = useState("id")
  const [order, setOrder] = useState("DESC")
  const [loading, setLoading] = useState(true)

  const [isPermission, setIsPermission] = useState(false)
  const navigate = useNavigate()
  const [data, setData] = useState({
    columns: [
      {
        label: "BOOKING ID",
        field: "bookingId",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("id"),
      },
      {
        label: "ORDER ID",
        field: "order_id",
        sort: "asc",
        width: 150,
        onClick: () => handleSort("order_id"),
      },

      {
        label: "CUSTOMER NAME",
        field: "customerName",
        sort: "asc",
        width: 200,
        onClick: () => handleSort("customerName"),
        
      },

      {
        label: "TIME SLOT",
        field: "timeSlot",
        sort: "asc",
        width: 200,
        onClick: () => handleSort("timeSlot"),

      },
      {
        label: "STATUS",
        field: "status",
        sort: "asc",
        width: 100,
        onClick: () => handleSort("statusShow"),

      },
    ],
    rows: [],
  })

  const filterData = () => {
    console.log("Filtering with:", filter);
    return data.rows.filter(row => {
      const value = row[filter.type] || "";
      return value.toString().toLowerCase().includes(filter.value.toString().toLowerCase());
    });
  }
  
  

  const handleCheckboxChange = id => {
    setSelectedRows(prevSelected => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }))
  }

  const filteredData = {
    columns: [
      ...(isPermission
        ? [
            {
              label: "",
              field: "select",
              sort: "disabled",
            },
          ]
        : []),
      ...data.columns,
    ],
    rows: filterData().map(row => {
      return {
        ...row,
        select: isPermission ? (
          <Input
            type="checkbox"
            checked={!!selectedRows[row.bookingId.key]}
            onChange={() => handleCheckboxChange(row.bookingId.key)}
          />
        ) : null,
      }
    }),
  }

 
  const fetchBookings = async (
    page,
    limit,
    sortBY,
    order,
    filterType,
    filterValue
  ) => {
    setLoading(true)
    try {
      const token = authHeader() // Fetch the authorization token
      const user = JSON.parse(localStorage.getItem("authUser")); // Retrieve the logged-in user's information
      const userId = user.user?.id
      const obj = JSON.parse(localStorage.getItem("permission"))
      const userRole = obj?.role_name

      // Check if the user is Admin
      const isAdmin = userRole === "Admin"
      console.log("isadmin",isAdmin)
      const config = {
        headers: {
          Authorization: token,
        },
      }

      // Start building the query parameters
      const params = new URLSearchParams({
        page,
        limit,
        sortBy: sortBY,
        order,
      })

      // Add filter parameters based on the filterType and filterValue
      if (filterType && filterValue) {
        params.append(filterType, filterValue)
      }
      // check to ge the owner specific data //
      if (!isAdmin) {
        params.append("owner_id", userId);
      }
      const response = await get(
        `/bookingswithfilters?${params.toString()}`,
        config
      )

      // Log each item to the console
      const res = response.data

      // Check if the response contains valid data
      if (res) {
        const mappedRows = res.map(item => {
          const detail = item.detail // Parse the detail string

          return {
            bookingId: (
              <div
                key={item.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/booking/${item.id}`)
                }}
                dangerouslySetInnerHTML={{
                  __html: `
              <div  style="color: #45c4ad;">${item?.id} </div>
            
            `,
                }}
              />
            ),
            customerName: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/booking/${item.id}`)
                }}
                dangerouslySetInnerHTML={{
                  __html: `
              <div  style="color: #45c4ad;">${detail?.billing.first_name} ${detail?.billing.last_name}</div>
            
            `,
                }}
              />
            ), // Concatenate first and last names
            // bookingDate: new Date(item.createdAt).toLocaleString(), // Format the created date
            timeSlot: detail?.items[0].Booking_slot || "", // Extract Booking_slot if available
            status: (
              <div
                dangerouslySetInnerHTML={{
                  __html: `
              <div  style="color: ${
                item.status == "completed"
                  ? "green"
                  : item.status == "pending"
                  ? "#e3b51e"
                  : item?.status==null?"#45c4ad":"red"
              };">${item?.status || item?.status==null && "Request"} </div>
            
            `,
                }}
              />
            ),

            order_id: detail?.order_id || "", // Extract Booking_slot if available
            // status: detail.items[0].status || "Pending", // Set a default status if not provided
            bookingType: "Booking", // Define how to set this value

     
          }
        })

        setTotalPages(response?.pages || 1)
        setData({
          columns: data.columns,
          rows: mappedRows || [],
        })
      } else {
        console.error("Invalid response structure", res)
        // Handle the case where response is not valid
        setData({ columns: data.columns, rows: [] })
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false)
    }
  }

  const deleteBookings = async () => {
    const token = authHeader()
    const idsToDelete = Object.keys(selectedRows).filter(id => selectedRows[id])
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async result => {
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: token,
          },
        }

        try {
          const res = await Promise.all(
            idsToDelete.map(id => del(`/bookings/${id}`, config))
          )

          Swal.fire({
            title: "Deleted!",
            text: res[0]?.message,
            icon: "success",
            timer: 3000,
          })
          setSelectedRows({})
          fetchBookings(currentPage, limit, sortBY, order)
        } catch (error) {
          console.log("An error occurred:", error)
          Swal.fire({
            title: "Error!",
            text: "An error occurred while deleting the apartment.",
            icon: "error",
          })
        }
      }
    })
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("permission"))
    const permissions = JSON.parse(obj?.permission)
    const userRole = obj?.role_name

    const isAdmin = userRole === "Admin"
    const hasPerm = hasPermission(
      permissions,
      "Bookings",
      ["Read-only", "Read-write"],
      isAdmin
    )

    if (isAdmin) {
      setIsPermission(true)
    } else if (!hasPerm) {
      navigate("/pages-404")
      setIsPermission(false)
    } else {
      const role = permissions["Bookings"]
      setIsPermission(role === "Read-write")
    }
  }, [])
  const handleSort = field => {
    // const direction = sortBY === field && order === "ASC" ? "DESC" : "ASC";
    setSortBY(field)
    setOrder(prevOrder => (prevOrder === "ASC" ? "DESC" : "ASC"))
    fetchBookings(currentPage, limit, field, order, filter.type, filter.value)
  }
  useEffect(() => {
    fetchBookings(currentPage, limit, sortBY, order, filter.type, filter.value);
  }, [currentPage, limit, sortBY, order, filter.value.length]);
  

  const handleTableChange = newPage => {
    setCurrentPage(newPage)
  }
  const selectedCount = Object.keys(selectedRows).filter(id => selectedRows[id]).length;
  document.title = "Bookings | SHS Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Bookings"
            breadcrumbItem={"List"}
            link="bookings"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <Col
                  className="col-12 d-flex justify-content-end mt-3 me-3"
                  style={{ paddingRight: "20px" }}
                >
                  {Object.keys(selectedRows).some(id => selectedRows[id]) ? (
                    <Button color="danger" onClick={deleteBookings}>
                      Delete Selected {selectedCount}
                    </Button>
                  ) : ""}
                </Col>
             
                  <CardBody>
                    <Row className="mb-3">
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterType">Filter By</Label>
                          <Input
                            type="select"
                            id="filterType"
                            value={filter.type}
                          onChange={e =>
                            setFilter({ type: e.target.value, value: "" })
                          }
                          >
                            <option value="">Select</option>
                            <option value="id">By Booking ID</option>
                            <option value="customerName">
                              By Customer Name
                            </option>
                            <option value="order_id">By Order ID</option>
                            <option value="timeSlot">By Time Slot</option>
                            <option value="status">By Status</option>
                            {/* <option value="bookingType">By Booking Type</option> */}
                          </Input>
                        </FormGroup>
                      </Col>
                      {filter.type && (
                      <Col md={2}>
                        <FormGroup>
                          <Label for="filterValue">Filter Value</Label>
                          <Input
                            type="text"
                            id="filterValue"
                            placeholder={`Enter ${filter.type}`}
                            value={filter.value}
                            onChange={e =>
                              setFilter({ ...filter, value: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                    )}
                    </Row>
                    {loading ? (
                  <Loader />
                ) :
           (     <><MDBDataTable
                      responsive
                      striped
                      bordered
                      searching={false}
                      data={filteredData}
                      paging={false} /><div className="d-flex justify-content-between align-items-center mt-3">
                        <FormGroup>
                          <Label for="limitSelect">Items per Page</Label>
                          <Input
                            type="select"
                            id="limitSelect"
                            value={limit}
                            onChange={e => {
                              setLimit(parseInt(e.target.value))
                              setCurrentPage(1)
                            } }
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </Input>
                        </FormGroup>

                        <MDBPagination className="mt-3">
                          <MDBPaginationItem disabled={currentPage === 1}>
                            <MDBPaginationLink
                              onClick={() => handleTableChange(currentPage - 1)}
                              tabIndex="-1"
                              aria-disabled={currentPage === 1}
                            >
                              Previous
                            </MDBPaginationLink>
                          </MDBPaginationItem>
                          {[...Array(totalPages).keys()].map(page => (
                            <MDBPaginationItem
                              key={page + 1}
                              active={currentPage === page + 1}
                            >
                              <MDBPaginationLink
                                onClick={() => handleTableChange(page + 1)}
                                style={{
                                  marginLeft: "3px", // Adds space to the left
                                  marginRight: "3px", // Adds space to the right
                                }}
                              >
                                {page + 1}
                              </MDBPaginationLink>
                            </MDBPaginationItem>
                          ))}
                          <MDBPaginationItem
                            disabled={currentPage === totalPages}
                          >
                            <MDBPaginationLink
                              onClick={() => handleTableChange(currentPage + 1)}
                              aria-disabled={currentPage === totalPages}
                            >
                              Next
                            </MDBPaginationLink>
                          </MDBPaginationItem>
                        </MDBPagination>
                      </div></>)   }
                  </CardBody>
                 
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Bookings
