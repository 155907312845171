import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import authHeader from "../../helpers/jwt-token-access/auth-token-header";
import { FaTimesCircle } from "react-icons/fa";
import "./MediaApartments.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "components/loader/Loader";

const MediaRooms = ({
  values,
  setActiveTab,
  onInputChange,
  onChangeHandlerFn,
}) => {
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [error, setError] = useState("");
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [newMediaName, setNewMediaName] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadingMedia, setUploadingMedia] = useState([]);

  // useEffect(() => {
  //   if (values && values.media) {
  //     try {
  //       const cleanedString = values.media.replace(/^"|"$/g, "");
  //       const firstParse = JSON.parse(cleanedString);
  //       const parsedData =
  //         typeof firstParse === "string" ? JSON.parse(firstParse) : firstParse;

  //       if (Array.isArray(parsedData)) {
  //         setSelectedMedia(parsedData);
  //       } else {
  //         console.error("Parsed data is not an array:", parsedData);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing media:", error);
  //     }
  //   }
  // }, [values]);
  useEffect(() => {
    if (values && values.media) {
      setSelectedMedia(values.media)
    }
  }, [values])

  const handleAcceptedMedia = media => {
    const defaultName = media[0].name || "Unnamed";
    setNewMediaName(defaultName);
    const newUpdatedMedia = media.map(file => ({
      file,
      path: newMediaName || file.name,
    }));
    setFilesToUpload(media);
    if (media.length === 1) {
      setModalOpen(true);
    } else {
      uploadMedia(newUpdatedMedia);
      setModalOpen(false);
    }
  };

  const uploadMedia = async files => {
    const token = authHeader();

    const newUploadingMedia = files.map((_, index) => ({ index, loading: true }));
    setUploadingMedia(newUploadingMedia);

    const uploadedFiles = await Promise.all(
      files.map(async ({ file, path }, index) => {
        try {
          const Data = new FormData();
          Data.append("media", file, path);

          const options = {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: Data,
          };

          const url = "https://psd2htmlx.com/beyondapi/api/media/upload";
          const response = await fetch(url, options);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response}`);
          }

          const data = await response.json();
          const mediaUrl = data?.data[0]?.media_url;
          const mediaType = data?.data[0]?.media_type.split("/")[0];
          const media_name = data?.data[0]?.media_name;

          return {
            media_url: mediaUrl,
            mediaType: mediaType,
            mediaName: media_name,
          };
        } catch (error) {
          console.error("An error occurred", error);
          Swal.fire({
            title: "Error!",
            text: error.message || "An error occurred while uploading media!",
            icon: "error",
          });
          return null;
        } finally {
          setUploadingMedia(prev =>
            prev.map(item =>
              item.index === index ? { ...item, loading: false } : item
            )
          );
        }
      })
    );

    const successfulUploads = uploadedFiles.filter(file => file !== null);
    const updatedMedia = [...selectedMedia, ...successfulUploads];
    setSelectedMedia(updatedMedia);

    onInputChange({ target: { name: "media", value: updatedMedia } });
  };

  const handleUploadFiles = () => {
    const newUpdatedNames = filesToUpload.map(file => ({
      file,
      path: newMediaName || file.name,
    }));

    uploadMedia(newUpdatedNames);
    setFilesToUpload([]);
    setModalOpen(false);
    setNewMediaName("");
  };

  const handleCancelUpload = () => {
    const newUpdatedNames = filesToUpload.map(file => ({
      file,
      path: newMediaName || file.name,
    }));
    uploadMedia(newUpdatedNames);
    setFilesToUpload([]);
    setModalOpen(false);
    setNewMediaName("");
  };

  const removeMedia = index => {
    const updatedMedia = selectedMedia.filter((_, i) => i !== index);
    setSelectedMedia(updatedMedia);
    onInputChange({ target: { name: "media", value: updatedMedia } });
  };

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setError("Only image files are allowed.");
      return;
    }
    setError("");
    handleAcceptedMedia(acceptedFiles);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("dragIndex", index);
    e.currentTarget.classList.add("dragging");
  };

  const handleDropMedia = (e, dropIndex) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData("dragIndex");
    if (dragIndex === dropIndex.toString()) return;

    const updatedMedia = [...selectedMedia];
    const draggedItem = updatedMedia[dragIndex];
    updatedMedia[dragIndex] = updatedMedia[dropIndex];
    updatedMedia[dropIndex] = draggedItem;

    updatedMedia.forEach((item, index) => {
      item.orderId = index + 1;
    });

    setSelectedMedia(updatedMedia);
    onInputChange({ target: { name: "media", value: updatedMedia } });
    e.currentTarget.classList.remove("dragging");
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDragEnd = e => {
    e.currentTarget.classList.remove("dragging");
  };

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Col className="col-12">
            <div className="mb-5">
              <Dropzone
                onDrop={handleDrop}
                accept={{ "image/*": [], "video/*": [] }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input name="img" {...getInputProps()} />
                      <div className="mb-3">
                        <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                      </div>
                      <h4>Drop media here or click to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              {error && <p className="text-danger">{error}</p>}
              <div className="dropzone-previews mt-3" id="file-previews" style={{ marginBottom: "20px" }}>
  {selectedMedia?.map((f, i) => {
   
    const isLastUploading = uploadingMedia.some(item => item.loading) && i === selectedMedia.length - 1;

    return (
      <Card
        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
        key={i + "-file"}
        draggable
        onDragStart={e => handleDragStart(e, i)}
        onDragOver={handleDragOver}
        onDrop={e => handleDropMedia(e, i)}
        onDragEnd={handleDragEnd}
        style={{ position: "relative" }}
      >
        {isLastUploading ? (
          <div style={{ height: "10rem", width: "11rem" }}>
            <Loader/> {/* Replace with your loader component */}
          </div>
        ) : (
          <>
            <FaTimesCircle
              size={20}
              onClick={() => removeMedia(i)}
              style={{
                position: "absolute",
                top: "-12px",
                right: "-12px",
                cursor: "pointer",
              }}
            />
            <Row className="align-items-center">
              <Col className="col-auto">
                {f.mediaType === "image" ? (
                  <img
                    data-dz-thumbnail=""
                    className="avatar-sm rounded bg-light"
                    style={{ height: "10rem", width: "11rem" }}
                    alt={f.name || "Uploaded media"}
                    src={f.media_url ? f.media_url : f.preview}
                  />
                ) : (
                  <video
                    src={f.media_url}
                    controls
                    style={{ height: "10rem", width: "11rem" }}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </Card>
    );
  })}
</div>

            </div>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modalOpen}>
        <ModalHeader>Rename Media</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            placeholder="Enter new media name"
            value={newMediaName}
            onChange={e => setNewMediaName(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancelUpload}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleUploadFiles}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("2")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={onChangeHandlerFn}>
            {id ? "Update" : "Submit"}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

MediaRooms.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onChangeHandlerFn: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default MediaRooms;
