import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, Col, Row, Button } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { get, post } from "helpers/api_helper"
import Swal from "sweetalert2"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import Loader from "components/loader/Loader"

const BookingsDetail = () => {
  const { id } = useParams()
  const [bookingData, setBookingData] = useState(null)
  const [loading, setLoading] = useState(true)
  // const [bookingStatus, setBookingStatus] = useState("");
  const navigate = useNavigate()
  const fetchBookingById = async () => {
    try {
      const token = authHeader()
      const config = { headers: { Authorization: token } }
      const res = await get(`bookings/${id}`, config)
      if (res.data) {
        setBookingData(res.data)
      } else {
        Swal.fire("Error", "Unable to fetch booking details", "error")
      }
    } catch (error) {
      Swal.fire(
        "Error",
        error.message || "Failed to fetch booking details",
        "error"
      )
    } finally {
      setLoading(false)
    }
  }

  const updateStatus = async status => {
    const token = authHeader()
    const config = { headers: { Authorization: token } }

    try {
      const response = await post(
        `/bookingStatus/${bookingData.detail.order_id}`,
        { status },
        config
      )

      Swal.fire({
        title: "Status Updated!",
        text: response.message,
        icon: "success",
        timer: 3000,
      }).then(() => {
        navigate("/bookings")
      })
    } catch (error) {
      console.error("Error updating status:", error)
      Swal.fire(
        "Error",
        error.response
          ? error.response.data.message
          : "Failed to update booking status",
        "error"
      )
    }
  }

  useEffect(() => {
    fetchBookingById()
  }, [id])

  if (loading) {
    return <Loader />
  }

  if (!bookingData) {
    return <p>No booking details available.</p>
  }

  const { order_id, billing, shipping, items, shipping_lines } =
    bookingData.detail || {}

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Bookings"
            link="bookings"
            breadcrumbItem="Booking Details"
          />
          <Card className="p-3">
            <Row>
              <Col md={6}></Col>
              {(bookingData.status === null ||
                bookingData.status === "processing") && (
                <Col md={6} className="text-end">
                  <Button
                    color="success"
                    onClick={() => updateStatus("completed")}
                  >
                    Accept Booking
                  </Button>
                  <Button
                    color="danger"
                    className="ms-2"
                    onClick={() => updateStatus("cancelled")}
                  >
                    Reject Booking
                  </Button>
                </Col>
              )}
            </Row>
            <h4>Booking #{order_id}</h4>
            <Row>
              <Col md={6}>
                <h5>Billing Info</h5>
                <p>
                  {billing.first_name} {billing.last_name}
                </p>
                <p>Email: {billing.email}</p>
                <p>Phone: {billing.phone}</p>
                <p>
                  Address: {billing.address_1}, {billing.city}, {billing.state},{" "}
                  {billing.postcode}
                </p>
              </Col>
              <Col md={6}>
                <h5>Shipping Info</h5>
                <p>
                  {shipping.first_name} {shipping.last_name}
                </p>
                <p>
                  Address: {shipping.address_1}, {shipping.city},{" "}
                  {shipping.state}, {shipping.postcode}
                </p>
                <p>Phone: {shipping.phone}</p>
                <p>
                  Shipping Method: {shipping_lines?.[0]?.method_title || "N/A"}
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6}>
                <h5>Order Info</h5>
                <p>
                  Order Date:{" "}
                  {new Date(bookingData.created_at).toLocaleString()}
                </p>
                <p>
                  Order Status:{" "}
                  <span
                    style={{
                      color:
                        bookingData.status === "completed"
                          ? "green"
                          : bookingData.status === "pending"
                          ? "#e3b51e"
                          : "red",
                    }}
                  >
                    {bookingData?.status}
                  </span>
                </p>
              </Col>
            </Row>
            <h5 className="mt-4">Items</h5>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>ID</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.product_name}</td>
                      <td>{item.product_id}</td>
                      <td>{item.quantity}</td>
                      <td>{item.subtotal}</td>
                      <td>{item.total}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BookingsDetail
