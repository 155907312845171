import React from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"

const Admin = () => {
  return (
    <React.Fragment>
      <Row style={{marginTop:"20px"}}>
        <Col md={6}>
          <Card>
            <CardTitle style={{ textAlign: "center" }}>Title</CardTitle>
            <CardBody>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry &apos s standard dummy text
              ever since the 1500s&lsquo when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <CardTitle>Title</CardTitle>
          <CardBody>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry&aposs standard dummy text
            ever since the 1500&lsquo when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </CardBody>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default Admin
