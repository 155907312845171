import React, { useState, useEffect } from "react"
import { Row, Col, FormGroup, Label, Input, Button, FormText } from "reactstrap"
import PropTypes from "prop-types"

const FeatureApartments = ({ values, onInputChange, setActiveTab }) => {
  const [selectedType, setSelectedType] = useState(
    values?.interior_exterior || ""
  )
  const [features, setFeatures] = useState(values?.features || {})
  const [errors, setErrors] = useState({})

  // List of features
  const featureList = [
    "Air Condition",
    "TV",
    "Kitchen",
    "Washer",
    "Elevator",
    "Doorman",
    "Oven",
    "Toaster",
    "Coffee Machine",
    "Parking Space",
    "Hot Water",
    "Wi-Fi",
    "Fridge",
    "Iron",
    "Terrace",
    "Microwave",
    "Dishwasher",
    "Blender",
    "Alarm System",
    "Sofa",
    "Bed",
  ]
  useEffect(() => {
    if (values?.features) {
      try {
        // Parse the features data if it's a string
        const parsedFeatures =
          typeof values.features === "string"
            ? JSON.parse(values.features)
            : values.features

        setFeatures(parsedFeatures)
      } catch (error) {
        console.error("Error parsing features:", error)
      }
    }
  }, [values?.features])
  const handleFeatureChange = event => {
    const { name, checked } = event.target
    setFeatures(prevFeatures => ({
      ...prevFeatures,
      [name]: checked,
    }))

    // Call the parent handler
    onInputChange({
      target: { name: "features", value: { ...features, [name]: checked } },
    })
  }

  const validate = () => {
    let validationErrors = {}
    if (!values.rooms) validationErrors.rooms = "This field is required."
    if (!values.size) validationErrors.size = "This field is required."
    // if (!values.flatmates) validationErrors.flatmates = "This field is required.";
    if (!values.bathrooms)
      validationErrors.bathrooms = "This field is required."

    if (!values.kitchen) validationErrors.kitchen = "This field is required."
    if (!values.latitude) validationErrors.latitude = "This field is required."
    if (!values.longitude)
      validationErrors.longitude = "This field is required."

    setErrors(validationErrors)
    return Object.keys(validationErrors).length === 0
  }

  const handleNext = () => {
    if (validate()) {
      setActiveTab("3")
    }
  }

  return (
    <div>
      <Row style={{ marginBottom: "40px" }}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="rooms">
                Rooms <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="number"
                name="rooms"
                id="rooms"
                onChange={onInputChange}
                value={values?.rooms || ""}
              />
              {errors.rooms && (
                <FormText color="danger">{errors.rooms}</FormText>
              )}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="size">
                Size (sq m) <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="number"
                name="size"
                id="size"
                onChange={onInputChange}
                value={values?.size || ""}
              />
              {errors.size && <FormText color="danger">{errors.size}</FormText>}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="bathrooms">
                Bathrooms <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="number"
                name="bathrooms"
                id="bathrooms"
                onChange={onInputChange}
                value={values?.bathrooms || ""}
              />
              {errors.bathrooms && (
                <FormText color="danger">{errors.bathrooms}</FormText>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="kitchen">
                Kitchen <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="kitchen"
                id="kitchen"
                onChange={onInputChange}
                value={values?.kitchen || ""}
              />
              {errors.kitchen && (
                <FormText color="danger">{errors.kitchen}</FormText>
              )}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="interior_exterior">Interior or Exterior</Label>
              <Input
                type="select"
                name="interior_exterior"
                id="interior_exterior"
                onChange={e => {
                  setSelectedType(e.target.value)
                  onInputChange(e) // Pass the select change to the parent
                }}
                value={selectedType || values?.interior_exterior}
              >
                <option value="">Select...</option>
                <option value="interior">Interior</option>
                <option value="exterior">Exterior</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Label for="">Additional Feature</Label>

          {featureList.map(feature => (
            <Col md={4} key={feature} className="d-flex align-items-center">
              <FormGroup check inline>
                <Label check className="form-check-label">
                  <Input
                    type="checkbox"
                    name={feature}
                    checked={features[feature] || features[values?.features]}
                    onChange={handleFeatureChange}
                  />
                  {feature}
                </Label>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </Row>
      <Row style={{ marginBottom: "25px" }}>
        <Col md={6}>
          <Button color="secondary" onClick={() => setActiveTab("1")}>
            Back
          </Button>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button color="primary" onClick={handleNext}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  )
}

FeatureApartments.propTypes = {
  values: PropTypes.shape({
    rooms: PropTypes.number,
    size: PropTypes.string,
    flatmates: PropTypes.string,
    bathrooms: PropTypes.string,
    kitchen: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    interior_exterior: PropTypes.string,
    features: PropTypes.objectOf(PropTypes.bool), // Added validation for features
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export default FeatureApartments
