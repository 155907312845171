import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
} from "reactstrap"
import classnames from "classnames"
import General from "components/general/ResidenceGeneral"
import FeatureResidence from "components/featureApartments/FeatureResidence"
import { useNavigate, useParams, useLocation } from "react-router-dom"

import "./StyleTable.scss"

import { get, post, put } from "helpers/api_helper"
import Swal from "sweetalert2"
import authHeader from "../../helpers/jwt-token-access/auth-token-header"
import MediaResidence from "components/mediaApartments/ResidenceMedia"
import Rooms from "../rooms/Rooms"

import EditerComponent from "components/editor/ResidenceEditor"

const ResidenceManage = () => {
  const [activeTab, setActiveTab] = useState("1")

  const [formData, setFormData] = useState({})
  const [forrommID, setForrommID] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  let URLdata = location?.state?.data

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const hasValues = () => Object.keys(formData).length > 0
  const { id } = useParams()

  const onChangeHandlerFn = data => {
    setFormData(prevData => ({
      ...prevData,
      [data?.name]: data?.value,
    }))

    submitHandler()
  }

  const onInputChange = evt => {
    setFormData(prevData => ({
      ...prevData,
      [evt.target.name]: evt.target.value,
    }))
  }

  const onAddressChange = evt => {
    const { name, value } = evt.target

    setFormData(prevData => ({
      ...prevData,
      address: {
        ...prevData.address, // Preserve existing fields in address
        [name.split(".")[1]]: value, // Update only the relevant field (city or street)
      },
    }))
  }

  const submitHandler = () => {
    const token = authHeader()

    const config = {
      headers: {
        Authorization: token,
      },
    }

    // Check if `id` is present to determine the correct URL and method
    const url = id ? `/residences/${id}` : "/residences"
    const method = id ? put : post // Use `put` for updating, `post` for creating

    // Call the appropriate method with the URL, formData, and config
    method(url, formData, config)
      .then(res => {
        localStorage.setItem(
          "residenceId",
          JSON.stringify(res.data.residence_id)
        )
        setForrommID(true)
        toggle("5")
        // setFormData(res.data)
        Swal.fire({
          position: "center",
          icon: "success",
          title:
            res?.message ||
            (id
              ? "Residence updated successfully"
              : "Residence created successfully with media"),
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (id) {
            navigate("/residence")
          } else {
            setActiveTab("5")
          }
        })
      })
      .catch(error => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: id ? "Error updating Residence" : "Error creating Residence",
          text:
            error.message || "An error occurred while processing the Residence",
          showConfirmButton: true,
        })
      })
  }

  const fetchRedenceById = async () => {
    try {
      const token = authHeader() // Fetch the authorization token

      const config = {
        headers: {
          Authorization: token,
        },
      }

      const res = await get(`/residences/${id}`, config)


      setFormData(res?.data)
    } catch (error) {
      console.log("error", error)
    }
  }
  const alertWhenNotResidence = ()=>{
    Swal.fire({
      position: "center",
      icon: "info",
      title: "Please Create Residence First!",
      timer: 2000,
    
    })
  }
  useEffect(() => {
    if (id) {
      fetchRedenceById()
    }

    const params = new URLSearchParams(location.search)
    // Get the "select" parameter
    const selectValue = params.get("data")

    if (selectValue == "rooms") {
      setActiveTab("5")
    }

    if (URLdata === "rooms") {
      setActiveTab("5")
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Home"
            title="Residence"
            breadcrumbItem={"Manage"}
            link="residence"
          />

          <Row>
            <Col className="col-12">
              <Col
                className="col-12 d-flex justify-content-end mt-3 me-3"
                style={{ paddingRight: "20px" }}
              ></Col>
              <Card style={{ padding: "20px" }}>
                <Row className="justify-content-center">
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggle("1")}
                        >
                          GENERAL
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => toggle("2")}
                        >
                          FEATURES
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => toggle("3")}
                        >
                          MEDIA
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "4" })}
                          onClick={() => toggle("4")}
                        >
                          OTHERS
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "5" })}
                          onClick={() => {
                         
                            if (forrommID === false && !id ) {
                              if (hasValues()) {
                              onChangeHandlerFn() // Only call this if formData has values
                              }else{
                                alertWhenNotResidence()

                              }
                            }else{
                              toggle("5")

                            }

                            // if(!id){

                            // }
                          }}
                        >
                          ROOMS
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <General
                      values={formData}
                      onInputChange={onInputChange}
                      onAddressChange={onAddressChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <FeatureResidence
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>{" "}
                  <TabPane tabId="3">
                    <MediaResidence
                      values={formData}
                      onInputChange={onInputChange}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <EditerComponent
                      values={formData}
                      onInputChange={onInputChange}

                      onChangeHandlerFn={() =>
                        forrommID === false
                          ? onChangeHandlerFn() // Only call this if formData has values
                          : toggle("5")
                      }

                      // {onChangeHandlerFn}
                      setActiveTab={setActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    {activeTab === "5" && <Rooms values={formData} />}
                    {/* <EditerComponent
                      values={formData}
                      onInputChange={onInputChange}
                      onChangeHandlerFn={onChangeHandlerFn}
                      setActiveTab={setActiveTab}
                    /> */}
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResidenceManage
